@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,700;1,900&family=Roboto:wght@100;300;400;500;700;900&display=swap');
body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.primary-bg {
    background-color: #ff5252;
}

.primary-text {
    color: #ff5252;
}

.shadow {
    box-shadow: -1px 0px 10px 0px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: -1px 0px 10px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: -1px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

.hr {
    width: 100px;
    background-color: #ff5252;
    height: 1px;
}

.btn:hover {
    background-color: #a70000;
}