#footer {
    height: 300px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("https://media.istockphoto.com/photos/courier-in-protective-mask-medical-gloves-delivers-takeaway-food-picture-id1218809014?b=1&k=20&m=1218809014&s=170667a&w=0&h=n_a3NOLbhMmjBbrZvo3nTALv7VaP9lGmH3Oz2IRwwEU=");
}

.con {
    background-color: rgba(0, 0, 0, .95);
    height: 300px;
    width: 100%;
    padding-top: 60px;
}

@media (max-width:760px) {
    #footer {
        height: fit-content;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url("https://media.istockphoto.com/photos/courier-in-protective-mask-medical-gloves-delivers-takeaway-food-picture-id1218809014?b=1&k=20&m=1218809014&s=170667a&w=0&h=n_a3NOLbhMmjBbrZvo3nTALv7VaP9lGmH3Oz2IRwwEU=");
    }
    .con {
        background-color: rgba(0, 0, 0, .95);
        height: fit-content;
        width: 100%;
        padding-top: 60px;
        padding-bottom: 20px;
    }
}

.bottom-part {
    background-color: rgba(0, 0, 0, .95);
    height: 70px;
    width: 100%;
}

footer {
    margin-top: 100px;
}

.quick {
    list-style-type: none;
}