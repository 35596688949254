#hero {
    height: 450px;
    width: 100%;
    background-image: url('../../images/store4.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
}

#blur {
    height: 450px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #F4F5F9;
}

#blur #title {
    font-family: 'Roboto', sans-serif;
    display: block;
    text-align: center;
    font-size: 48px;
    font-weight: 700;
    line-height: 1.2em;
    letter-spacing: normal;
    margin-bottom: 15px;
}

#who_title {
    font-family: 'Roboto', sans-serif;
    display: block;
    font-size: 35px;
    font-weight: 700;
    line-height: 1.2em;
    letter-spacing: normal;
    margin-bottom: 15px;
    margin-top: 15px;
}

@media (max-width: 768px) {
    #blur #title {
        font-size: 30px;
        margin-bottom: 25px;
    }
    #hero {
        height: 65vh;
    }
    #blur {
        height: 65vh;
    }
}